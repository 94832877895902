@tailwind base;
@tailwind components;
@tailwind utilities;

nav a {
  @apply p-3 text-gray-300 hover:bg-gray-800;
}

nav a.active {
  @apply text-white sm:border-b-2;
}
